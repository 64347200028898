import React, { useEffect } from 'react';

import AppCreate from '../components/Appcreation';


const AppCreate2 = () => {

  return (<>
    <AppCreate />
  </>
  );
};

export default AppCreate2;