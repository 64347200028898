import React, { useState } from 'react';
import {
  AppBar,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Grid,
  Box,
  TextField,
} from '@mui/material';
import { navigate } from 'gatsby';
const { format, addDays } = require('date-fns');
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ErrorIcon from '@mui/icons-material/Error';

const dataApiUrl = process.env.DATA_API_KEY_GEN_URL;
//Enum for API types
const API_TYPES = {
  FREEMIUM: 'freemium',
  SSO: 'sso'
};


const PageContainer = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [appName, setAppName] = useState('');
  const [appDescription, setAppDescription] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(API_TYPES.SSO);
  const [selectedApi, setSelectedApi] = useState(API_TYPES.SSO);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const steps = ['App Details', 'Select Plan', 'Select API'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseErrorModal = () => {
    setErrorOpen(false);
    navigate('/dashboard');
  };


  const generateAPIOnFinish = () => {
    const genKeysUrl = `${dataApiUrl}/api/apim/apikeys`;
    const token = localStorage.getItem('id_token');
    const today = new Date();

    // Format today's date in dd-mm-yyyy
    const formattedToday = format(today, 'dd-MM-yyyy');

    // Get the date 365 days from today
    const futureDate = addDays(today, 365);

    const formattedFutureDate = format(futureDate, 'dd-MM-yyyy');

    // check if org_id is present in local storage else redirect to login
    if (localStorage.getItem('org_id')) {
      const orgId = localStorage.getItem('org_id');
      fetch(genKeysUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'org_id': orgId,
        },
        body: JSON.stringify({
          'name': appName,
          'api': selectedApi,
          'description': appDescription,
          'creation_date': formattedToday,
          'expiry_date': formattedFutureDate
        }),
      })
        .then(response => {
          console.log(response.status);
          if (response.ok) {
            navigate('/dashboard');
            return response.json();
          }
          else {
            setErrorMessage('An error occurred while generating API keys');
            setErrorOpen(true);
          }
        })
        .catch(error => {
          console.error('Error:', error);
          setErrorMessage('An error occurred while generating API keys');
          setErrorOpen(true);
        });
    }
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
    case 0:
      return (
        <Grid container spacing={2} style={{ marginTop: '3rem' }}>
          <Grid item xs={12}>
            <Typography variant="h6">App Details</Typography>
          </Grid>
          <Grid item xs={12}>

            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 3, width: '25ch', height: '5ch' },
              }}
              noValidate
              autoComplete="off"
            ><input id="outlined-basic" label="Outlined"
                type="text"
                placeholder="App Name"
                value={appName}
                onChange={(e) => setAppName(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <textarea
              placeholder="App Description"
              value={appDescription}
              onChange={(e) => setAppDescription(e.target.value)}
              style={{ marginLeft: '25px', height: '100%', width: '26ch' }}
            />
          </Grid>
        </Grid>
      );
    case 1:
      return (
        <Grid container spacing={2} style={{ marginTop: '3rem' }}>
          <Grid item xs={12}>
            <Typography variant="h6">Select Plan</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="plan"
                name="plan"
                value={selectedPlan}
                onChange={(e) => setSelectedPlan(e.target.value)}
              >
                <FormControlLabel value={API_TYPES.FREEMIUM} control={<Radio disabled />} label="Freemium" />
                <FormControlLabel value={API_TYPES.SSO} control={<Radio />} label="SSO" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      );
    case 2:
      return (
        <Grid container spacing={2} style={{ marginTop: '3rem' }}>
          <Grid item xs={12}>
            <Typography variant="h6">Select API</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ width: '30%' }}>
              <InputLabel>Select API</InputLabel>
              <Select
                labelId="api-select-label"
                id="api-select"
                value={selectedApi}
                onChange={(e) => setSelectedApi(e.target.value)}
              >
                <MenuItem value="SSO Insights">SSO Insights</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      );
    default:
      return 'Unknown stepIndex';
    }
  };

  return (
    <Container style={{ marginTop: '2rem', minHeight: '80vh' }}>
      <Dialog open={errorOpen} onClose={handleCloseErrorModal}>
        <DialogTitle>
          <ErrorIcon color="error" fontSize="large" style={{ verticalAlign: 'middle', marginRight: '8px' }} />
          Error
        </DialogTitle>
        <DialogContent dividers>
          <div>{errorMessage}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Paper elevation={3} style={{ padding: '2rem' }}>
        <Typography variant="h4" align="center">
          Create Application
        </Typography>
        <div style={{ marginTop: '5rem', borderBottom: '20px solid #999' }}>
          <Stepper activeStep={activeStep} alternativeLabel style={{ marginTop: '5rem' }}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {activeStep === steps.length ? (
          <div style={{ marginTop: '5rem' }}>
            <Typography variant="h5" align="center">
              All steps completed - BRAVO!
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
              <Button variant="contained" color="primary" onClick={generateAPIOnFinish}>
                Finish
              </Button>
            </div>
          </div>
        ) : (
          <div>
            {getStepContent(activeStep)}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
              <Button disabled={activeStep === 0} onClick={handleBack} variant="outlined" color="primary">
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </Paper>
    </Container>
  );
};

export default PageContainer;
